/* Fichier Loader */

@import url('./palette.css');

.loader {
    position: fixed;
    background-color: var(--secondary-color);
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    pointer-events: none;
}

.loader .loader-wrap {
    height: 100%;
}

.loader .loader-wrap .des-wrap {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-area: inner;
    width: 100%;
}

@media only screen and (device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    .loader .loader-wrap .des-wrap {
        max-height: 500px;
        position: absolute;
        top: 50vh;
        left: 50vw;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@media only screen and (device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .loader .loader-wrap .des-wrap {
        max-height: 500px;
        position: absolute;
        top: 50vh;
        left: 50vw;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 576px) {
    .loader .loader-wrap .des-wrap {
        max-height: 400px;
        position: absolute;
        top: 50vh;
        left: 50vw;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 414px) {
    .loader .loader-wrap .des-wrap {
        max-height: 300px;
    }
}

@media screen and (max-width: 380px) {
    .loader .loader-wrap .des-wrap {
        max-height: 275px;
    }
}

.loader .loader-wrap .des {
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    opacity: 0;
    pointer-events: none;
}

.loader .loader-wrap .des:nth-child(6) {
    opacity: 1;
}

.loader .loader-wrap .des-inner {

    display: block;
    color: var(--background-color);
    font-size: 5rem;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Akira", sans-serif;
}

@media (min-width: 1280px) {
    .loader .loader-wrap .des-inner {
        font-size: 3rem;
    }
}

@media (min-width: 1440px) {
    .loader .loader-wrap .des-inner {
        font-size: 3.5rem;
    }
}

@media (min-width: 1900px) {
    .loader .loader-wrap .des-inner {
        font-size: 5rem;
    }
}

@media screen and (max-width: 1280px) {
    .loader .loader-wrap .des-inner {
        font-size: 2.7rem;
    }
}

@media (max-width: 1199px) {
    .loader .loader-wrap .des-inner {
        font-size: 2.5rem;
    }
}

@media (max-width: 991px) {
    .loader .loader-wrap .des-inner {
        font-size: 1.6rem;
    }
}

@media (max-width: 768px) {
    .loader .loader-wrap .des-inner {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 576px) {
    .loader .loader-wrap .des-inner {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .loader .loader-wrap .des-inner {
        font-size: 1rem;
    }
}

@media screen and (max-width: 414px) {
    .loader .loader-wrap .des-inner {
        font-size: 1rem;
    }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des-inner {
        font-size: 1.45rem;
    }
}

@media screen and (max-width: 380px) {
    .loader .loader-wrap .des-inner {
        font-size: 0.8rem;
    }
}

@media only screen and (device-width: 380px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des-inner {
        font-size: 0.8rem;
    }
}

@media only screen and (device-width: 380px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    .loader .loader-wrap .des-inner {
        font-size: 0.8rem;
    }
}

.loader .loader-wrap .des-inner--top {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
}

@media screen and (min-width: 3840px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 92%, 0);
        transform: translate3d(0, 92%, 0);
    }
}

@media screen and (min-width: 2500px) and (max-width: 3839px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 38%, 0);
        transform: translate3d(0, 38%, 0);
    }
}

@media only screen and (device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 4%, 0);
        transform: translate3d(0, 4%, 0);
    }
}

@media (max-width: 1199px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 45%, 0);
        transform: translate3d(0, 45%, 0);
    }
}

@media (max-width: 991px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 69%, 0);
        transform: translate3d(0, 69%, 0);
    }
}

@media only screen and (device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 27%, 0);
        transform: translate3d(0, 27%, 0);
    }
}

@media (max-width: 768px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 130%, 0);
        transform: translate3d(0, 130%, 0);
    }
}

@media screen and (max-width: 576px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 74%, 0);
        transform: translate3d(0, 74%, 0);
    }
}

@media screen and (max-width: 480px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 76%, 0);
        transform: translate3d(0, 76%, 0);
    }
}

@media screen and (max-width: 414px) {
    .loader .loader-wrap .des-inner--top {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
}

.loader .loader-wrap .des-inner--bottom {
    -webkit-transform: translate3d(0, -27%, 0);
    transform: translate3d(0, -27%, 0);
}

@media (max-width: 991px) {
    .loader .loader-wrap .des-inner--bottom {
        -webkit-transform: translate3d(0, -43%, 0);
        transform: translate3d(0, -43%, 0);
    }
}

@media only screen and (device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .loader .loader-wrap .des-inner--bottom {
        -webkit-transform: translate3d(0, -18%, 0);
        transform: translate3d(0, -18%, 0);
    }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des-inner--bottom {
        -webkit-transform: translate3d(0, -23%, 0);
        transform: translate3d(0, -23%, 0);
    }
}

@media only screen and (device-width: 380px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des-inner--bottom {
        -webkit-transform: translate3d(0, -25%, 0);
        transform: translate3d(0, -25%, 0);
    }
}

@media only screen and (device-width: 380px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    .loader .loader-wrap .des-inner--bottom {
        -webkit-transform: translate3d(0, -25%, 0);
        transform: translate3d(0, -25%, 0);
    }
}

.loader .loader-wrap .des--bottom {
    -webkit-transform: translate3d(0, -27%, 0);
    transform: translate3d(0, -27%, 0);
}

@media screen and (min-width: 3840px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
}

@media screen and (min-width: 2500px) and (max-width: 3840px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 14%, 0);
        transform: translate3d(0, 14%, 0);
    }
}

@media only screen and (device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, -28%, 0);
        transform: translate3d(0, -28%, 0);
    }
}

@media (max-width: 1199px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 5%, 0);
        transform: translate3d(0, 5%, 0);
    }
}

@media (max-width: 991px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 27%, 0);
        transform: translate3d(0, 27%, 0);
    }
}

@media only screen and (device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0);
    }
}

@media screen and (max-width: 576px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0);
    }
}

@media screen and (max-width: 480px) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0);
    }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des--bottom {
        -webkit-transform: translate3d(0, 18%, 0);
        transform: translate3d(0, 18%, 0);
    }
}

.loader .loader-wrap .des-inner--stroke {

    -webkit-text-stroke: 2px var(--background-color);
    text-stroke: 2px var(--background-color);
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: transparent;
}

@media screen and (max-width: 576px) {
    .loader .loader-wrap .des-inner--stroke {
        -webkit-text-stroke: 1px var(--background-color);
        text-stroke: 1px var(--background-color);
    }
}

.loader .loader-wrap .des--full {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.loader .loader-wrap .des--full .des-inner {
    -webkit-transform: translateY(0.25rem);
    -ms-transform: translateY(0.25rem);
    transform: translateY(0.25rem);
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des--full .des-inner {
        -webkit-transform: translateY(0.1rem);
        -ms-transform: translateY(0.1rem);
        transform: translateY(0.1rem);
    }
}

@media only screen and (device-width: 380px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .loader .loader-wrap .des--full .des-inner {
        -webkit-transform: translateY(0.1rem);
        -ms-transform: translateY(0.1rem);
        transform: translateY(0.1rem);
    }
}

@media only screen and (device-width: 380px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    .loader .loader-wrap .des--full .des-inner {
        -webkit-transform: translateY(0.1rem);
        -ms-transform: translateY(0.1rem);
        transform: translateY(0.1rem);
    }
}

.loader.loading--change {
    background-color: var(--background-color);
}

.loader.loading--change .des-inner--stroke {
    -webkit-text-stroke: 2px var(--secondary-color);
    text-stroke: 2px var(--secondary-color);
}

@media screen and (max-width: 576px) {
    .loader.loading--change .des-inner--stroke {
        -webkit-text-stroke: 1px var(--secondary-color);
        text-stroke: 1px var(--secondary-color);
    }
}

.loader.loading--change .des-inner {
    color: var(--secondary-color);
}

.loader.loading--ending {
    transition: transform 0.8s cubic-bezier(1, 0, 1, .05);
    transform: scale(1000);
}

.loader.loading--end {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}
