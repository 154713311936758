@import url("./tailwind.css");
:root {
    
    --primary-color: #444c62;
    /* --primary-color: #9a0cf7; */
    --secondary-color: #f5efe1;
    --accent-color: #2f476e;
    --accent-color-light:#80bdff;
    /* --accent-color: #623CEA; */
    --background-color: #1A1A1A;
    --background-accent-color:#f5eee623;
    --text-color: #1A1A1A;
}


@font-face {
    font-family: 'Akira';
    src: url('/public/fonts/Akira Super Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DM';
    src: url("/public/fonts/DMSans-ExtraLight.ttf");
}

::-moz-selection {
    background-color: var(--secondary-color);
    color: var(--accent-color);
}
/* 
html{
    width: 100vw;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 1vw;
}
html {
    font-size:1vw
  }
  @media not all and (min-width:1024px) {
    html {
      font-size:1.5vw
    }
  }
  @media not all and (min-width:768px) {
    html {
      font-size:2vw;
    }
  }
  @media not all and (min-width:640px) {
    html {
      font-size:3.5vw
    }
  } */


/* ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
} */

/* .scrollbar {
    z-index: 7;
    position: fixed;
    top: 0;
    right: 0;
    width: 5px;
    height: 0;
    opacity: 1;
    background-color: #f5efe1;
    border-radius: 0 0 10% 10%;
} */

body {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-family: -apple-system, "Akira", 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
    overflow: auto;
}
