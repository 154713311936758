/* MainPage.css */

@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('./palette.css');

.accent{
    color: var(--accent-color);
}
.bg-accent{
    background-color: var(--accent-color);
}

.box{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    --tw-bg-opacity: 1;
    background-color: var(--secondary-color);
    padding: 1.5rem;
    --tw-text-opacity: 1;
    animation: appearbox 0.2s ease-out forwards;
}

.box .intro-icon , .box .about-icon{
    animation: rotateonappear 0.5s ease-out forwards;
}

@keyframes rotateonappear {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
    
}

@keyframes appearbox {
    0% {
        opacity: 0;
        scale: 0.7;
    }

    100% {
        opacity: 1;
        scale: 1;
        transform: translateY(0%);
        transform: translateX(0%);
    }
}

