.projet-page{
    color : var(--secondary-color);
    font-family: DM;
}

/* Titres principaux */
.projet-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: Akira;
}

.projet-page h2 {
    font-size: 2rem;
    margin: 1.5rem 0 1rem;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
    font-family: Akira;
    border-bottom: none;
    padding-bottom: 0;
}

.projet-page h3 {
    font-size: 1.5rem;
    margin: 1rem 0 0.75rem;
}

/* Texte */
.projet-page p {
    font-size: 1rem;
    margin: 1rem 0;
}

.projet-page a {
    color: var(--accent-color-light);
    text-decoration: none;
    font-style: italic;
    font-weight: bolder;
}

.projet-page a:hover {
    text-decoration: underline;
}

/* Listes */
.projet-page ol,ul,menu{
    list-style:revert;
}
.projet-page ul {
    padding-left: 1.5rem;
    margin: 1rem 0;
}

.projet-page ul li {
    margin-bottom: 0.5rem;
}
.projet-page ol {
    color :#fff;
    padding-left: 1.5rem;
    margin: 1rem 0;
}

/* .projet-page ol li::marker {
    color: red;
} */

.projet-page ol li {
    margin-bottom: 0.5rem;
}

/* Citations */
.projet-page blockquote {
    margin: 1rem 0;
    padding: 1rem;
    background: #f1f1f1;
    border-left: 4px solid #007BFF;
    font-style: italic;
}

.projet-page p > em{
    display: block;
    text-align: center;
}

.projet-page strong{
    font-weight: 900;
    font-family:DM;
}

/* Code */
.projet-page code {
    color: var(--secondary-color); /* Harmonisé avec le texte principal */
    font-family: "Courier New", Courier, monospace; /* Police monospace pour le code */
    font-size: 0.85rem; /* Taille légèrement inférieure au texte standard */
    padding: 0.2rem 0.4rem; /* Marges internes pour rendre le code plus visible */
    border-radius: 4px; /* Coins arrondis pour correspondre au style des images */
    display: inline; /* Inline par défaut */
}

/* Code blocs */
.projet-page pre {
    background-color: var(--background-accent-color); /* Fond sombre pour les blocs de code */
    color: var(--secondary-color); /* Texte clair pour le contraste */
    font-family: "Courier New", Courier, monospace; /* Monospace pour les blocs */
    font-size: 0.85rem; /* Taille légèrement réduite */
    padding: 1rem; /* Espacement généreux autour du code */
    border-radius: 8px; /* Coins arrondis pour correspondre au style général */
    overflow-x: auto; /* Permet le défilement horizontal pour le long code */
    margin: 1rem 0; /* Marges pour espacer les blocs */
    white-space: pre-wrap; /* Permet de conserver les sauts de ligne */
    word-wrap: break-word; /* Évite le débordement */
}


/* Images */
.projet-page img {
    max-width: 100%;
    height: auto;
    margin: 1rem auto 0 auto;
    border-radius: 8px;
}

.projet-page img[alt="linkedin"] {
    width: 20px;
    height: 20px;
    display: inline;
    margin: 1rem 0 0 0;
    border-radius: 0;

}

.projet-page img[alt="docker"],img[alt="git"],img[alt="github"],img[alt="gitlab"],img[alt="python"],img[alt="html"],img[alt="ubuntu"],img[alt="vercel"],img[alt="jetbrain"],img[alt="tailwind"],img[alt="react"],img[alt="html"],img[alt="html"],img[alt="html"],img[alt="html"]{
    width: 50px;
    height: 50px;
    display: inline;
    margin: 1rem 0 0 0;
    border-radius: 0;
}


/* Responsive Typography */
@media (max-width: 1200px) {
    .projet-page h1 {
        font-size: 2.25rem;
    }
    .projet-page h2 {
        font-size: 1.75rem;
    }
    .projet-page h3 {
        font-size: 1.25rem;
    }
    .projet-page p {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px ) {
    .projet-page h1 {
        font-size: 2rem;
    }
    .projet-page h2 {
        font-size: 1.5rem;
    }
    .projet-page h3 {
        font-size: 1.15rem;
    }
    .projet-page p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px ) {
    .projet-page h1 {
        font-size: 1.75rem;
    }
    .projet-page h2 {
        font-size: 1.25rem;
    }
    .projet-page h3 {
        font-size: 1rem;
    }
    .projet-page p {
        font-size: 0.85rem;
    }
}