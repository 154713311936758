.hasard {
    position: relative;
    color: var(--secondary-color);
    background-color: var(--background-color);
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 1em;
    border: 2px solid transparent;
    transition: 0.3s;
}

.hasard:hover {
    border: 1px solid var(--secondary-color);
}

.hasard::before {
    content: '';
    position: absolute;
    inset: 0 8px;
    border-left: 2px solid var(--secondary-color);
    border-right: 2px solid var(--secondary-color);
    transition: 0.8s;
}

.hasard::after {
    content: '';
    position: absolute;
    inset: 8px 0;
    border-top: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    transition: 0.8s;
}

.hasard:hover::before {
    inset: 0px -12px;
    transform: skewY(15deg);
}

.hasard:hover::after {
    inset: -12px 0px;
    transform: skewX(15deg);
}
