*,
  :after,
  :before {
    box-sizing:border-box;
    border:0 solid #e5e7eb
  }
  :after,
  :before {
    --tw-content:""
  }
  :host,
  html {
    line-height:1.5;
    -webkit-text-size-adjust:100%;
    -moz-tab-size:4;
    tab-size:4;
    font-family:ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-feature-settings:normal;
    font-variation-settings:normal;
    -webkit-tap-highlight-color:transparent
  }
  body {
    margin:0;
    line-height:inherit
  }
  hr {
    height:0;
    color:inherit;
    border-top-width:1px
  }
  abbr:where([title]) {
    text-decoration:underline dotted
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size:inherit;
    font-weight:inherit
  }
  a {
    color:inherit;
    text-decoration:inherit
  }
  b,
  strong {
    font-weight:bolder
  }
  code,
  kbd,
  pre,
  samp {
    font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-feature-settings:normal;
    font-variation-settings:normal;
    font-size:1em
  }
  small {
    font-size:80%
  }
  sub,
  sup {
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline
  }
  sub {
    bottom:-.25em
  }
  sup {
    top:-.5em
  }
  table {
    text-indent:0;
    border-color:inherit;
    border-collapse:collapse
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family:inherit;
    font-feature-settings:inherit;
    font-variation-settings:inherit;
    font-size:100%;
    font-weight:inherit;
    line-height:inherit;
    letter-spacing:inherit;
    color:inherit;
    margin:0;
    padding:0
  }
  button,
  select {
    text-transform:none
  }
  button,
  input:where([type=button]),
  input:where([type=reset]),
  input:where([type=submit]) {
    -webkit-appearance:button;
    background-color:transparent;
    background-image:none
  }
  :-moz-focusring {
    outline:auto
  }
  :-moz-ui-invalid {
    box-shadow:none
  }
  progress {
    vertical-align:baseline
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height:auto
  }
  [type=search] {
    -webkit-appearance:textfield;
    outline-offset:-2px
  }
  ::-webkit-search-decoration {
    -webkit-appearance:none
  }
  ::-webkit-file-upload-button {
    -webkit-appearance:button;
    font:inherit
  }
  summary {
    display:list-item
  }
  blockquote,
  dd,
  dl,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre {
    margin:0
  }
  fieldset {
    margin:0
  }
  fieldset,
  legend {
    padding:0
  }
  menu,
  ol,
  ul {
    list-style:none;
    margin:0;
    padding:0
  }
  dialog {
    padding:0
  }
  textarea {
    resize:vertical
  }
  input::placeholder,
  textarea::placeholder {
    opacity:1;
    color:#9ca3af
  }
  [role=button],
  button {
    cursor:pointer
  }
  :disabled {
    cursor:default
  }
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display:block;
    vertical-align:middle
  }
  img,
  video {
    max-width:100%;
    height:auto
  }
  [hidden] {
    display:none
  }
  ::selection {
    background-color:rgba(86,84,73,.3)
  }
  html {
    font-size:1vw
  }
  @media not all and (min-width:1024px) {
    html {
      font-size:1.5vw
    }
  }
  @media not all and (min-width:768px) {
    html {
      font-size:2vw;
    }
  }
  @media not all and (min-width:640px) {
    html {
      font-size:3.5vw
    }
  }
  body {
    overscroll-behavior-y:none;
    --tw-bg-opacity:1;
    background-color:rgb(17 18 13/var(--tw-bg-opacity));
    font-family:var(--font-body),sans-serif;
    font-weight:300
  }
  *,
  :after,
  :before {
    --tw-border-spacing-x:0;
    --tw-border-spacing-y:0;
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness:proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width:0px;
    --tw-ring-offset-color:#fff;
    --tw-ring-color:rgba(59,130,246,.5);
    --tw-ring-offset-shadow:0 0 #0000;
    --tw-ring-shadow:0 0 #0000;
    --tw-shadow:0 0 #0000;
    --tw-shadow-colored:0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style: 
  }
  ::backdrop {
    --tw-border-spacing-x:0;
    --tw-border-spacing-y:0;
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness:proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width:0px;
    --tw-ring-offset-color:#fff;
    --tw-ring-color:rgba(59,130,246,.5);
    --tw-ring-offset-shadow:0 0 #0000;
    --tw-ring-shadow:0 0 #0000;
    --tw-shadow:0 0 #0000;
    --tw-shadow-colored:0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style: 
  }
  .pointer-events-none {
    pointer-events:none
  }
  .pointer-events-auto {
    pointer-events:auto
  }
  .fixed {
    position:fixed
  }
  .absolute {
    position:absolute
  }
  .relative {
    position:relative
  }
  .bottom-0 {
    bottom:0
  }
  .left-0 {
    left:0
  }
  .top-0 {
    top:0
  }
  .z-10 {
    z-index:10
  }
  .z-20 {
    z-index:20
  }
  .z-30 {
    z-index:30
  }
  .col-span-3 {
    grid-column:span 3/span 3
  }
  .col-span-4 {
    grid-column:span 4/span 4
  }
  .col-span-5 {
    grid-column:span 5/span 5
  }
  .col-span-8 {
    grid-column:span 8/span 8
  }
  .col-span-full {
    grid-column:1/-1
  }
  .row-span-1 {
    grid-row:span 1/span 1
  }
  .row-span-4 {
    grid-row:span 4/span 4
  }
  .row-span-5 {
    grid-row:span 5/span 5
  }
  .row-span-8 {
    grid-row:span 8/span 8
  }
  .row-span-9 {
    grid-row:span 9/span 9
  }
  .ml-auto {
    margin-left:auto
  }
  .mr-2 {
    margin-right:.5rem
  }
  .mt-4 {
    margin-top:1rem
  }
  .block {
    display:block
  }
  .inline-block {
    display:inline-block
  }
  .flex {
    display:flex
  }
  .grid {
    display:grid
  }
  .hidden {
    display:none
  }
  .aspect-\[3\/2\] {
    aspect-ratio:3/2
  }
  .aspect-\[400\/450\] {
    aspect-ratio:400/450
  }
  .aspect-square {
    aspect-ratio:1/1
  }
  .size-\[0\.9rem\] {
    width:.9rem;
    height:.9rem
  }
  .size-\[1\.35rem\] {
    width:1.35rem;
    height:1.35rem
  }
  .size-\[2\.75rem\] {
    width:2.75rem;
    height:2.75rem
  }
  .size-\[8rem\] {
    width:8rem;
    height:8rem
  }
  .size-full {
    width:100%;
    height:100%
  }
  .h-0 {
    height:0
  }
  .h-8 {
    height:2rem
  }
  .h-\[1px\] {
    height:1px
  }
  .h-\[2px\] {
    height:2px
  }
  .h-auto {
    height:auto
  }
  .h-full {
    height:100%
  }
  .h-screen {
    height:100vh
  }
  .max-h-\[75rem\] {
    max-height:75rem
  }
  .min-h-\[50rem\] {
    min-height:50rem
  }
  .w-\[30vw\] {
    width:30vw
  }
  .w-full {
    width:100%
  }
  .max-w-\[10ch\] {
    max-width:10ch
  }
  .max-w-\[25rem\] {
    max-width:25rem
  }
  .max-w-\[35rem\] {
    max-width:35rem
  }
  .origin-left {
    transform-origin:left
  }
  .origin-top {
    transform-origin:top
  }
  .\!translate-x-0 {
    --tw-translate-x:0px!important
  }
  .\!translate-x-0,
  .\!translate-y-0 {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))!important
  }
  .\!translate-y-0 {
    --tw-translate-y:0px!important
  }
  .-translate-x-full {
    --tw-translate-x:-100%
  }
  .-translate-x-full,
  .-translate-y-full {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .-translate-y-full {
    --tw-translate-y:-100%
  }
  .translate-x-0 {
    --tw-translate-x:0px
  }
  .translate-x-0,
  .translate-x-full {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .translate-x-full {
    --tw-translate-x:100%
  }
  .translate-y-0 {
    --tw-translate-y:0px
  }
  .translate-y-0,
  .translate-y-full {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .translate-y-full {
    --tw-translate-y:100%
  }
  .\!scale-100 {
    --tw-scale-x:1!important;
    --tw-scale-y:1!important;
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))!important
  }
  .scale-0 {
    --tw-scale-x:0;
    --tw-scale-y:0
  }
  .scale-0,
  .scale-x-0 {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .scale-x-0 {
    --tw-scale-x:0
  }
  .transform {
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .grid-cols-12 {
    grid-template-columns:repeat(12,minmax(0,1fr))
  }
  .grid-cols-subgrid {
    grid-template-columns:subgrid
  }
  .grid-rows-10 {
    grid-template-rows:repeat(10,minmax(0,1fr))
  }
  .grid-rows-subgrid {
    grid-template-rows:subgrid
  }
  .flex-col {
    flex-direction:column
  }
  .items-center {
    align-items:center
  }
  .items-baseline {
    align-items:baseline
  }
  .justify-between {
    justify-content:space-between
  }
  .justify-evenly {
    justify-content:space-evenly
  }
  .gap-2 {
    gap:.5rem
  }
  .gap-4 {
    gap:1rem
  }
  .overflow-hidden {
    overflow:hidden
  }
  .overflow-y-auto {
    overflow-y:auto
  }
  .rounded-\[16px\] {
    border-radius:16px
  }
  .rounded-xl {
    border-radius:.75rem
  }
  .border {
    border-width:1px
  }
  .border-secondary {
    --tw-border-opacity:1;
    border-color:rgb(86 84 73/var(--tw-border-opacity))
  }
  .bg-primary {
    --tw-bg-opacity:1;
    background-color:rgb(216 207 188/var(--tw-bg-opacity))
  }
  .bg-secondary {
    --tw-bg-opacity:1;
    background-color:var(--secondary-color)
  }
  .bg-gradient-to-b {
    background-image:linear-gradient(to bottom,var(--tw-gradient-stops))
  }
  .from-primary\/0 {
    --tw-gradient-from:hsla(41,26%,79%,0) var(--tw-gradient-from-position);
    --tw-gradient-to:hsla(41,26%,79%,0) var(--tw-gradient-to-position);
    --tw-gradient-stops:var(--tw-gradient-from),var(--tw-gradient-to)
  }
  .to-primary\/50 {
    --tw-gradient-to:hsla(41,26%,79%,.5) var(--tw-gradient-to-position)
  }
  .object-contain {
    object-fit:contain
  }
  .object-cover {
    object-fit:cover
  }
  .object-bottom {
    object-position:bottom
  }
  .p-4 {
    padding:1rem
  }
  .px-2 {
    padding-left:.5rem;
    padding-right:.5rem
  }
  .px-4 {
    padding-left:1rem;
    padding-right:1rem
  }
  .py-0 {
    padding-top:0;
    padding-bottom:0
  }
  .py-3 {
    padding-top:.75rem;
    padding-bottom:.75rem
  }
  .pb-2 {
    padding-bottom:.5rem
  }
  .pb-8 {
    padding-bottom:2rem
  }
  .pb-\[2px\] {
    padding-bottom:2px
  }
  .pb-\[calc\(1\.8rem-2px\)\] {
    padding-bottom:calc(1.8rem - 2px)
  }
  .pt-3\.5 {
    padding-top:.875rem
  }
  .pt-\[1\.8rem\] {
    padding-top:1.8rem
  }
  .font-heading {
    font-family:var(--font-heading),serif
  }
  .text-2xl {
    font-size:1.5rem;
    line-height:2rem
  }
  .text-\[3\.2rem\] {
    font-size:3.2rem
  }
  .text-\[3\.6rem\] {
    font-size:3.6rem
  }
  .text-base {
    font-size:1rem;
    line-height:1.5rem
  }
  .text-lg {
    font-size:1.125rem;
    line-height:1.75rem
  }
  .font-medium {
    font-weight:500
  }
  .font-normal {
    font-weight:400
  }
  .uppercase {
    text-transform:uppercase
  }
  .italic {
    font-style:italic
  }
  .leading-\[100\%\] {
    line-height:100%
  }
  .leading-\[120\%\] {
    line-height:120%
  }
  .leading-\[135\%\] {
    line-height:135%
  }
  .text-primary {
    --tw-text-opacity:1;
    color: var(--text-color);
  }
  .text-secondary {
    --tw-text-opacity:1;
    color: var(--secondary-color);
  }
  .antialiased {
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
  }
  .\!opacity-100 {
    opacity:1!important
  }
  .opacity-0 {
    opacity:0
  }
  .blur {
    --tw-blur:blur(8px);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }
  .transition-transform {
    transition-property:transform;
    transition-timing-function:cubic-bezier(.4,0,.2,1);
    transition-duration:.15s
  }
  .duration-700 {
    transition-duration:.7s
  }
  .ease-out {
    transition-timing-function:cubic-bezier(0,0,.2,1)
  }
  .absolute-center {
    position:fixed;
    top:50%;
    left:50%;
    --tw-translate-x:-50%;
    --tw-translate-y:-50%;
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .hide-scrollbar {
    -ms-overflow-style:none;
    scrollbar-width:none
  }
  .hide-scrollbar::-webkit-scrollbar {
    display:none
  }
  .hover\:scale-105:hover {
    --tw-scale-x:1.05;
    --tw-scale-y:1.05;
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  @media not all and (min-width:1024px) {
    .max-lg\:col-span-4 {
      grid-column:span 4/span 4
    }
    .max-lg\:col-span-6 {
      grid-column:span 6/span 6
    }
    .max-lg\:col-span-8 {
      grid-column:span 8/span 8
    }
    .max-lg\:col-span-full {
      grid-column:1/-1
    }
    .max-lg\:h-auto {
      height:auto
    }
    .max-lg\:max-h-none {
      max-height:none
    }
    .max-lg\:min-h-\[20rem\] {
      min-height:20rem
    }
    .max-lg\:min-h-\[5rem\] {
      min-height:5rem
    }
    .max-lg\:grid-rows-none {
      grid-template-rows:none
    }
    .max-lg\:gap-4 {
      gap:1rem
    }
    .max-lg\:overflow-y-visible {
      overflow-y:visible
    }
    .max-lg\:py-6 {
      padding-top:1.5rem;
      padding-bottom:1.5rem
    }
  }
  @media not all and (min-width:768px) {
    .max-md\:col-span-full {
      grid-column:1/-1
    }
    .max-md\:block {
      display:block
    }
    .max-md\:hidden {
      display:none
    }
    .max-md\:w-\[50vw\] {
      width:50vw
    }
    .max-md\:flex-col {
      flex-direction:column
    }
    .max-md\:justify-center {
      justify-content:center
    }
  }
  